.app{
    height: 100vh;
    // background-color: rgb(240, 122, 191);
    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        // height: 50vh;
        // background-color: rgb(250, 128, 28);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;

        @media only screen and (max-width: 600px) {
            height: calc(100vh - 90px);
        } 

        // SNAP SCROLLING
        scroll-snap-type:y mandatory;
        scrollbar-width: none; //firefox
        //other browsers:
        &::-webkit-scrollbar{
            display: none;
        }

        // ALL SECTIONS
        > *{
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align:start;

            @media only screen and (max-width: 600px) {
                height: calc(100vh - 90px);
            } 
        }


    }
}

