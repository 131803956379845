@import "../../global.scss";



.contact{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
        
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;

    } 


    .left{

        flex: 1;
        overflow: hidden;
        &.img{
            height: 100%;

            @media only screen and (max-width: 600px) {
                width: 100%;
            } 
            
        }
    }

    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        @media only screen and (max-width: 600px) {
            width: 300px;
        } 

        h2{
            font-size: 30px;
        }

        .status-message {
            opacity: 0;
          }
          .success {
            opacity: 1;
            color: rgb(86, 235, 86);
            font-weight: bold;
          }
          .failure {
            opacity: 1;
            color: rgb(196, 87, 87);
            font-weight: bold;
          }


        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            div[role='alert'] {
                color:#B89439;
                margin-top: 1em;
                font-weight: bold;
            }
            
            
            

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;
                border-radius: 20px;

                @include mobile{
                    width: 200px;
                    height: 20px;
                    
                }

                @media only screen and (max-width: 600px) {
                    width: 155px;
                    height: 20px;
                } 
            }

            textarea{
                width: 300px;
                height: 200px;
                font-size: 14px;
                border-radius: 20px;

                @include mobile{
                    width: 200px;
                    height: 100px;
                    
                }

                @media only screen and (max-width: 600px) {
                    width: 155px;
                    height: 75px;
                } 
            }

            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: #B89439;
                border: none;
                border-radius: 20px;
                font-weight: 500;
                cursor: pointer;

               

                &:focus{
                    outline: none;
                }

            }

            span{
                color: #B89439;
                font-weight: bold;
            }
        }

    }




}