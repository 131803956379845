@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 600px) {
        padding-top: 50px;
    }

    h1{
        font-size: 50px;
        overflow: hidden;

        @include mobile{
            font-size: 20px;
        } 
        
        @media only screen and (max-width: 600px) {
            font-size: 20px;
        } 

        
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            margin: 10px 0;
            font-size: 20px;
            flex-wrap: wrap;
            justify-content: center;
        }  
        
        @media only screen and (max-width: 600px) {
            margin: 10px 0;
            font-size: 20px;
            justify-content: center;
        } 

        li{
            font-size: 14px;
            font-weight: bold;
            margin-right: 50px;
            padding: 7px;
            border-radius: 20px;cursor: pointer;

            &.active{
                background-color:#B89439;
                color: $mainColor;
            }

            @media only screen and (max-width: 600px) {
                padding: 6px;
                font-size: 12px;
                margin-right: 0px;
            } 
        }
    }

    .container{
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile{
            // width: 100%;
            // width: 100px;
            // height: 100px;
        }

        .item{
            width: 220px;
            height: 100px;
            border-radius: 20px;
            border: 1px solid lightgrey;
            margin: 10px 20px;
            display: flex;
            flex-direction: inherit;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5 ease;
            cursor: pointer;

            @include mobile{
                width: 70px;
                height: 70px;
            }

            @media only screen and (max-width: 600px) {
                width: 140px;
                height: 70px;
            } 

            h3{
                position: absolute;
                font-size: 20px;
            }

            img{
                width: 75%;
                height: 75%;
                border-radius: 4px;
                object-fit: contain;
                z-index: 1;

            }

            &:hover{
                background-color: $mainColor;

                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }

        }
    }

}