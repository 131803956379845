@import "../../global.scss";

//scss global variable

.topbar{
    width: 100%;
    height: 70px;
    background-color: white;
    color:$mainColor;
    position: fixed;
    top:0;
    z-index: 3;
    transition: all 1s ease;
    overflow-y: hidden;
    

    @media only screen and (max-width: 600px) {
        height: 40px;
        
    } 

    &.active{
        background-color: $mainColor;
        color: white;


        .hamburger{
            span{
                &:first-child{
                    background-color: white;
                    transform:rotate(45deg);
                }
                &:nth-child(2){

                    opacity:0;
                }
                &:last-child{
                    background-color: white;
                    transform:rotate(-45deg);
                }
            }
        }

    }


}

.wrapper{
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left{
        display: flex;
        align-content: center;
        
        .logo{
            font-size: 40px;
            font-weight: 700;
            text-decoration: none;
            color: inherit;
            margin-right: 40px;
            // font-style: italic;

            @media only screen and (min-width: 768px) {
                font-size: 30px;
                font-weight: 700;
                margin-right: 5px;
            } 

            @media only screen and (max-width: 600px) {
                font-size: 14px;
                font-weight: 700;
                margin-right: 1.5px;
            } 
        }

        .itemContainer{
            display: flex;
            align-items: center;
            margin-left: 30px;

            @media only screen and (min-width: 768px) {
                margin-left: 2px;
            } 

            @media only screen and (max-width: 600px) {
                margin-left: 0.25px;
            } 

            @include mobile{
                display: none;
            }

            .icon{
                font-size: 18px;
                margin-right: 5px;

                @media only screen and (min-width: 768px) {
                    margin-right: 2px;

                } 
    
                @media only screen and (max-width: 600px) {
                    margin-right: 0.5px;

                } 
    
            }

            span{
                font-size: 15px;
                font-weight: 500;

                a{
                    text-decoration: none;
                    color:  #B89439;
                }

                @media only screen and (min-width: 768px) {
                    font-size: 12px;
                    font-weight: 500;
                } 
    
                @media only screen and (max-width: 600px) {
                    font-size: 8.25px;
                    font-weight: 500;
                } 
            }
        }

    }

    .right{

        .hamburger{
            width:32px;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor:pointer;

            span{
                width: 100%;
                height: 3px;
                background-color: $mainColor;
                transform-origin: left;
                transition: all 2s ease;
            }
        }
    }

    
}