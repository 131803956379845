@import "../../global.scss";



.works{
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        height: 50px;
        width: 25px;
        position: absolute;
        cursor: pointer;

        @include mobile{
            display: none;
        }

        &.left{
            left: 100px;
            transform: rotateY(180deg);
            background-color: mediumvioletred;
        }

        &.right{
            right:100px;
            background-color: orange;
        }
    }



    .slider{
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;


        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container{
            width: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;

            .item{
                width: 700px;
                height: 100%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                    width: 80%;
                    height: 150px;
                    margin: 15px 0;

                }
        

                .left{
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: color #f6f6f6;

                    .leftContainer{
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include mobile{
                            width: 100%;
                            height: 100%;
        
                        }
                    

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: white;
                            align-items: center;
                            justify-content: center;

                            @include mobile{
                                width: 20px;
                                height: 20px;
            
                            }

                            img{
                                width: 37px;
                                align-items: center;
                                justify-content: center;

                                @include mobile{
                                    width: 15px;
                                   
                
                                }
                                
                            }
                        }
                        h2{
                            font-size: 20px;

                            @include mobile{
                                font-size: 13px;
                               
            
                            }
                        }
                        p{
                            font-size: 13px;

                            @include mobile{
                                display: none;
                               
            
                            }
                        }
                        span{
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                .right{
                    flex: 8;
                    height: 100%;
                    background-color: lightcoral;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img{
                        width: 400px;
                        // transition: rotate(-25deg);
                        overflow: hidden;
                    }

                }
            }
        }
    }

}