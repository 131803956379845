$mainColor:#15023a;

$width: 768px;

@mixin mobile{

    @media (maxiwdith: #{$width}){
        @content
    }

}