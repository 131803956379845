@import "../../global.scss";

.intro{
    background-color:white;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    // @media only screen and (min-width: 768px) {
    //     margin-right: 2px;

    // } 

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding-top: 50px;

    } 

    .left{
        flex: 0.5;
        // background-color: yellow;
        overflow: hidden; 

        @media only screen and (max-width: 600px) {
            flex:0.35;
    
        } 

        .imgContainer{
            width: 700px;
            height: 700px;
            background-color: #B89439;
            border-radius: 60%;
            // border-color: $mainColor;
            // border-width: thick;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;

            @include mobile{
                align-items: flex-start;
            }

            @media only screen and (max-width: 600px) {
                align-items: flex-start;
                width: 262.5px;
                height: 262.5px;
                border-radius: 60%;
        
            } 

            img{
                height:60%;
                // overflow: hidden;

                @include mobile{
                    height: 50%;
                }

                @media only screen and (max-width: 600px) {
                    height: 75%;
            
                } 
            }
        }
    }
    .right{
        flex: 0.5;
        position: relative;
        // background-color: white;
        @media only screen and (max-width: 600px) {
            overflow: hidden;
            flex:0.35;
        } 

        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }

            @media only screen and (max-width: 600px) {
                height: 70%;
                padding-left: 0;
                align-items: center;
                overflow: hidden;
            } 

            h1{
                font-size: 60px;
                margin: 10px 0;

                // @include mobile{
                //     font-style: 40px;
                // }

                @media only screen and (max-width: 600px) {
                    font-size: 20px;
            
                } 
            }
            h2{
                font-size: 35px;

                @media only screen and (max-width: 600px) {
                    font-size: 25px;
            
                } 
             
            }
            h3{
                font-size: 30px;

                @include mobile{
                    font-size: 20px;
                }

                @media only screen and (max-width: 600px) {
                    font-size: 20px;
            
                } 

                span{
                    font-size: inherit;
                    // color: rgb(110, 220, 20);
                    color: #B89439;
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink {

                   50%{
                       opacity:1;
                   } 
                   100%{
                       opacity:0;
                   } 
                }
            }

        }
        a{
            position: absolute;
            bottom: 225px;
            left:48%;
            text-decoration: none !important;

            @media only screen and (max-width: 600px) {
                position: static;
                bottom: 95px;
                left: 32.55%;
                text-align: center;
                justify-content: center;
                // position: absolute;
                // bottom: 10px;
                
            } 

            .downarrow{
                width: 8.5em;
                height: 2.5em;
                margin: 2em auto;
                border-radius: 500px;
                background-color: #B89439;
                animation: arrowBlink 3s infinite;
                text-align: center;
                color: #fff;
                // text-decoration: none;
                
                font-weight: 900;

                @media only screen and (max-width: 600px) {
                    height: 2.5em;
                    margin: 0 auto;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                } 
            }

            img{
                width: 30px;
                animation: arrowBlink 3s infinite;
            }

            @keyframes arrowBlink{
                100%{
                    opacity: 0;
                }
            }
        }
    }
    
}